<template>
  <section class="article-filter">
    <form class="article-filter__form" @submit.prevent="filterArticles()">
      <div class="article-filter__form-panel-1">
        <Select
          v-model="filter.site"
          :options="sites"
          :label="$t('article.filter.site')"
          id="filter_site"
          disable-form-group
          @change="loadAuthorsBySite"
        />
        <Select
          v-model="filter.rubric"
          :options="rubricsBySite"
          :label="$t('article.filter.rubric')"
          id="filter_rubric"
          :level="true"
          disable-form-group
        />
        <div>
          <div class="article-filter__input-extra-structure">
            <label class="article-filter__input-extra-structure-main-label">
              {{ $t('article.filter.search_in') }}
            </label>
            <input
              class="article-filter__input-extra-structure-input with-gap"
              type="radio"
              v-model="filter.searchIn"
              value="title"
              id="fulltext_title"
              checked
            >
            <label
              class="article-filter__input-extra-structure-label"
              for="fulltext_title"
            >
              {{ $t('article.filter.title') }}
            </label>
            <input
              class="article-filter__input-extra-structure-input with-gap"
              type="radio"
              v-model="filter.searchIn"
              value="fulltext"
              id="fulltext_fulltext"
            >
            <label
              class="article-filter__input-extra-structure-label"
              for="fulltext_fulltext"
            >
              {{ $t('article.filter.fulltext') }}
            </label>
          </div>
          <Input
            v-model="filter.title"
            id="filter_title"
            disable-form-group
            :no-label="true"
          />
        </div>
        <Input
          v-model.trim="filter.id"
          id="filter_id"
          :label="$t('article.filter.id_url')"
          disable-form-group
        />
        <Datepicker
          v-show="advancedFilter"
          type="date"
          v-model="filter.dateFrom"
          id="filter_dateFrom"
          :label="$t('article.filter.published_since')"
          disable-form-group
        />
        <Datepicker
          v-show="advancedFilter"
          type="date"
          v-model="filter.dateTo"
          id="filter_dateTo"
          :label="$t('article.filter.published_until')"
          disable-form-group
        />
        <Select
          v-show="advancedFilter"
          v-model="filter.type"
          :options="articleTypeValues"
          :label="$t('article.filter.type')"
          id="filter_type"
          disable-form-group
        />
        <MultiSelect
          v-show="advancedFilter"
          v-model="filter.authors"
          :options="authors"
          :custom-label="getAuthorCustomName"
          label="name"
          track-by="id"
          id="filter_authors"
          :label-desc="$t('article.filter.authors')"
          disable-form-group
        />
        <MultiSelect
          v-show="advancedFilter"
          v-model="filter.users"
          :options="users"
          label="username"
          track-by="id"
          id="filter_users"
          :label-desc="$t('article.filter.users')"
        />
        <MultiSelect
          v-if="!vlm && tags && advancedFilter"
          v-model="filter.tags"
          :options="tags"
          label="title"
          track-by="id"
          :preselect-first="true"
          :loading="isLoading"
          :internal-search="false"
          :options-limit="300"
          :limit="10"
          :max-height="600"
          :show-no-results="false"
          @search-change="findTag"
          id="filter_tags"
          :label-desc="$t('article.filter.tags')"
        />
        <MultiSelect
          v-show="advancedFilter"
          v-model="filter.categories"
          :options="categories"
          label="title"
          track-by="id"
          id="filter_categories"
          :label-desc=" $t('article.filter.categories')"
        />
      </div>
      <div class="article-filter__form-panel-2">
        <div class="article-filter__btn-search">
          <ButtonSearch />
        </div>
        <div class="article-filter__btn-advaned">
          <BtnArticleShowAdvancedFilter
            :on-click="toggleAdvancedFilter"
            :is-enabled="advancedFilter"
          />
        </div>
        <div class="article-filter__separator"></div>
        <ButtonResetFilter :on-click="resetFilters" />
        <BtnArticleCreate v-if="isCreateButtonVisible" />
      </div>
    </form>
    <div
      v-if="disableStatusFilter === false"
      class="article-filter__btns"
    >
      <label
        v-for="btn in btns"
        :key="btn.id"
        class="article-filter__btn"
        :class="{
          'article-filter__btn--active': filter.status === btn.value,
          [`article-filter__btn__${btn.value}`]: true
        }"
      >
        <input
          type="radio"
          v-model="filter.status"
          :value="btn.value"
          :id="btn.id"
          @change="btn.changeAction()"
        >
        {{ $t(`article.filter.${btn.text}`) }}
      </label>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import MultiSelect from '../form/select/MultiSelect'
import Input from '../form/inputs/Input'
import Datepicker from '../form/Datepicker'
import Select from '../form/select/Select'
import CoreApi from '../../api/core'
import ArticleTypeMixin from '../mixins/valueObject/ArticleTypeMixin'
import BtnArticleCreate from '@/components/buttons/BtnArticleCreate'
import ButtonSearch from '@/components/buttons/ButtonSearch'
import ButtonResetFilter from '@/components/buttons/ButtonResetFilter'
import PermissionService from '@/services/PermissionService'
import BtnArticleShowAdvancedFilter from '@/components/buttons/BtnArticleShowAdvancedFilter'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'
import config from '@/config'

export default {
  name: 'ArticleFilter',
  mixins: [ArticleTypeMixin],
  props: {
    disableStatusFilter: {
      type: Boolean,
      default: false
    },
    showStatusSelect: {
      type: Boolean,
      default: false
    },
    showEnabledSites: {
      type: Boolean,
      default: true
    },
    customFilterReset: {
      type: Function,
      required: false
    },
    hiddenOnHp: {
      type: Boolean,
      default: false
    },
    articleListType: {
      type: String, // articles, relatedArticles
      default: 'articles'
    }
  },
  components: {
    BtnArticleCreate,
    ButtonSearch,
    ButtonResetFilter,
    BtnArticleShowAdvancedFilter,
    MultiSelect,
    Input,
    Select,
    Datepicker
  },
  data () {
    return {
      authors: [],
      isLoading: false,
      tags: [],
      rubricsBySite: [],
      advancedFilter: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_PERMISSIONS,
      btns: [
        {
          id: 'filter_status_my_last_modified',
          text: 'my_last_modified',
          value: 'my_last_modified',
          changeAction: this.filterArticles
        },
        { id: 'filter_status_my_drafts', text: 'my_drafts', value: 'my_drafts', changeAction: this.filterArticles },
        {
          id: 'filter_status_my_articles',
          text: 'my_articles',
          value: 'my_articles',
          changeAction: this.filterArticles
        },
        { id: 'filter_status_all', text: 'all', value: 'all', changeAction: this.filterArticles },
        { id: 'filter_status_draft', text: 'all_drafts', value: 'drafts', changeAction: this.filterArticles },
        { id: 'filter_status_review', text: 'to_review', value: 'review', changeAction: this.filterArticles },
        { id: 'filter_status_ready', text: 'waiting_to_publish', value: 'ready', changeAction: this.filterArticles },
        { id: 'filter_status_published', text: 'published', value: 'published', changeAction: this.filterArticles },
        { id: 'filter_status_edited', text: 'just_edited', value: 'edited', changeAction: this.getEditedArticles }
      ]
    }
  },
  computed: {
    ...mapGetters('aiStore', ['recommendedIds']),
    ...mapGetters(['vlm']),
    isCreateButtonVisible () {
      const currentSiteId = this.$store.getters['article/filter']?.site
      const currentArticleSiteName = currentSiteId && this.$store.getters['site/siteById'](currentSiteId)?.name
      return this.hasPermissionsForSite(this.requiredPermissions.createButton, currentArticleSiteName)
    },
    sites () {
      if (this.showEnabledSites) {
        return this.$store.getters['site/enabledSites'](MODULE_ARTICLE)
      } else {
        const sites = this.$store.getters['site/all']
        return sites.filter(site => site.enabled)
      }
    },
    users () {
      return this.$store.getters['user/all']
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    categories () {
      return this.$store.getters['category/all']
    },
    filter () {
      return this.$store.getters['article/filter']
    },
    watchedSite () {
      return this.filter.site
    },
    statuses () {
      return [
        {
          id: 'all',
          title: '---'
        },
        {
          id: 'drafts',
          title: this.$t('article.status_draft')
        },
        {
          id: 'review',
          title: this.$t('article.status_review')
        },
        {
          id: 'ready',
          title: this.$t('article.status_ready')
        },
        {
          id: 'published',
          title: this.$t('article.status_published')
        }
      ]
    }
  },
  methods: {
    hasPermissionsForSite (permission, articleSiteName) {
      return this.$store.getters['user/hasPermission'](permission, articleSiteName)
    },
    async loadAuthorsBySite () {
      await this.$store.dispatch('author/fetchAuthorsBySiteId', this.filter.site)
      this.authors = this.$store.getters['author/allBySite'](this.filter.site)
    },
    getAuthorCustomName (author) {
      return this.$store.getters['author/getAuthorCustomName'](author)
    },
    findTag (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tags = this.$store.getters['tag/list']
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchAtricles () {
      if (this.articleListType === 'relatedArticles') {
        this.$store.dispatch('article/fetchArticlesForRelatedList', {
          hiddenOnHp: this.hiddenOnHp ? 'hiddenOnHp' : '',
          firstPagelimit: this.vlm ? config.article.list.limit : 5
        })
      } else {
        this.$store.dispatch('article/fetch', this.hiddenOnHp ? 'hiddenOnHp' : '')
      }
    },
    filterArticles ({ resetPage } = { resetPage: true }) {
      this.filter.documentIds = []
      if (this.showEnabledSites) {
        this.$store.commit('article/setSearchInEnabledSites', true)
      } else {
        this.$store.commit('article/setSearchInEnabledSites', false)
      }
      if (resetPage) {
        this.$store.commit('article/setPage', 1)
      }
      this.$store.commit('article/setFilter', this.filter)
      this.fetchAtricles()
    },
    resetFilters () {
      if (this.customFilterReset) {
        this.customFilterReset()
      } else {
        this.$store.commit('article/resetFilter')
        this.fetchAtricles()
      }
    },
    getEditedArticles () {
      CoreApi().get('/ArticleLock')
        .then(response => {
          if (response.data.length > 0) {
            this.filter.documentIds = response.data.map(activity => activity.articleUuid)
          } else {
            const nonExistingId = '00000000-0000-0000-0000-000000000000'
            this.filter.documentIds = [nonExistingId]
          }
          this.filter.status = 'edited'
          this.$store.commit('article/setPage', 1)
          this.$store.commit('article/setFilter', this.filter)
          this.fetchAtricles()
        })
        .catch(error => {
          console.log(error)
        })
    },
    async loadMainRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.filter.site)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.filter.site)
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    }
  },
  created () {
    this.loadMainRubricsBySite()
    this.loadAuthorsBySite()
    if (this.filter.site === 0 && this.currentUser.defaultSite !== null) {
      this.filter.site = this.currentUser.defaultSite
    }
    if (this.filter.rubric === 0 && this.currentUser.defaultRubric !== null) {
      this.filter.rubric = this.currentUser.defaultRubric
    }
    this.filterArticles({ resetPage: false })
  },
  watch: {
    watchedSite (newVal, oldVal) {
      if (this.filter.site !== this.currentUser.defaultSite) {
        this.filter.rubric = 0
      }
      this.loadMainRubricsBySite()
      this.loadAuthorsBySite()
      const siteName = this.$store.getters['site/getSiteNameById'](this.filter.site)
      if (oldVal !== undefined) {
        this.$gtm.trackView('virtual_pageview', null, siteName)
      }
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  .article-filter {
    display: grid;
    grid-gap: rem(20px);
    &__form {
      display: grid;
      grid-gap: rem(20px);
      @include bp(0 7) {
        margin-top: rem(15px);
      }
      @include bp(11) {
        grid-template-columns: auto max-content;
      }
    }
    &__form-panel-1 {
      display: grid;
      grid-gap: rem(10px);
      align-items: end;
      @include bp(7) {
        grid-template-columns: 1fr 1fr;
        grid-gap: rem(20px);
      }
      @include bp(16) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    &__form-panel-2 {
      margin-top: rem(25px);
      display: grid;
      grid-template-columns: max-content max-content max-content max-content max-content;
      grid-gap: rem(10px);
      @include bp(0 11) {
        margin-top: 0;
        order: -1;
      }
    }
    &__separator {
      margin: 0 rem(10px);
      background: #D1DBE4;
      width: 1px;
      height: rem(40px);
    }
    &__input-extra-structure {
      > label, input {
        font-family: "Roboto", sans-serif;
        font-size: rem(14px);
        font-weight: 400;
        color: #8A96AC;
      }
      > label:first-child {
        margin-right: rem(10px);
      }
      > label:not(:first-child) {
        padding-left: rem(23px);
      }
      > label:nth-child(2) {
        margin-right: rem(4px);
      }
    }
    &__input-extra-structure-label {
      margin-right: rem(10px);
    }
    &__btns {
      background: #fff;
      border-top-left-radius: rem(6px);
      border-top-right-radius: rem(6px);
      padding: rem(20px) rem(20px) rem(20px) rem(20px);
      display: flex;
      gap: rem(13px);
      position: relative;
      overflow-x: auto;
      &::after {
        position: absolute;
        left: rem(20px);
        bottom: 0;
        width: calc(100% - 40px);
        height: rem(1px);
        background: #d1dbe4;
        content: '';
      }
    }
    &__btn {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 600;
      cursor: pointer;
      position: relative;
      display: block;
      white-space: nowrap;
      padding: rem(8px) rem(12px) rem(8px) rem(12px);
      border-radius: rem(7px);
      &:hover:not(.article-filter__btn--active) {
        box-shadow: 1px 0 5px #6599fe91;
      }
      &--active {
        &::after {
          position: absolute;
          left: 0;
          bottom: rem(-20px);
          width: 100%;
          height: rem(2px);
          background: #6599FE;
          content: '';
          z-index: 1;
        }
      }
      &__my_drafts, &__drafts, &__edited {
        color: #F57BA3;
        background: #FFEBEE;
        &.article-filter__btn--active {
          border: 1px solid #F57BA3;
          box-shadow: 1px 0 5px #F57BA391;
        }
      }
      &__published {
        color: #5AB963;
        background: #ECF7ED;
        &.article-filter__btn--active {
          border: 1px solid #5AB963;
          box-shadow: 1px 0 5px #5AB96391;
        }
      }
      &__review, &__ready {
        color: #ffa200;
        background: #fff3e0;
        &.article-filter__btn--active {
          border: 1px solid #ffa200;
          box-shadow: 1px 0 5px #ffa20091;
        }
      }
      &__my_last_modified, &__my_articles, &__all {
        color: #8A96AC;
        background: #F5F5F9;
        &.article-filter__btn--active {
          border: 1px solid #8A96AC;
          box-shadow: 1px 0 5px #8A96AC91;
        }
      }
    }
  }
</style>
